.Grid {
  /* display: grid; */
  /* grid-gap: 1px; */
  /* grid-template-columns: auto auto 38em auto auto; */
  /* grid-template-rows: 15em auto 25em; */
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  max-width: 700px;
  margin: auto;
  margin-top: 60px;
}

.Child {
  background: linear-gradient(
      16deg,
      rgb(11, 30, 56) 0%,
      rgb(10, 37, 73) 42%,
      rgb(8, 45, 95) 100%
    ),
    linear-gradient(to right, rgb(37, 62, 99), rgb(11, 31, 70));
  border: 0.3em solid rgba(23, 60, 139, 0.514);
  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  border-radius: 2.5em;
  grid-column: 3;
  grid-row: 2;
  text-align: center;
  color: white;
  /* -webkit-box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44); */
  /* box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44); */
}

.curtain {
  background-color: rgba(0, 0, 0, 0.466);
  position: static;
  height: 99em;
  width: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  z-index: 2;
  filter: blur(8px);
  -webkit-backdrop-filter: blur(1.5em);
  backdrop-filter: blur(1.5em);
}
